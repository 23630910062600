body{
 background: #333;
  color: #ccc;
}

.App {
  text-align: center; 
 
}

a{
  color: #b55a5a;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.nav > li > a:hover, .nav > li > a:focus {
  background-color: transparent;
  border-color: transparent;
  border-bottom: 1px solid #ddd;

}

.nav > li > a {
  color: #ccc;

}

.vid_holder {
  /*z-index: -100;*/
  padding:50% 0 0 0; 
  position:relative;
  overflow:hidden;
  pointer-events:none;
}

iframe {
  pointer-events:all;
}